import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import { MdKeyboardArrowDown } from 'react-icons/md';
import api from '~/services/api';
import { AlertBox, DataList } from './styles';
import Select, { AsyncSelect } from '@atlaskit/select';

interface IFormData {
  text: string | any;
  option: { label: string; value: number | string } | any;
}

// type TipoPessoa = 'Cliente' | 'Fornecedor' | 'Loja';

interface Options {
  label: string | undefined;
  value: number | undefined | string;
}

interface Produto {
  cod_produto: string;
  des_produto: string;
}

interface Select {
  label: string;
  value: number;
}

interface BuscaVasilhameProps {
  onChange(e: any): void;
  children?: ReactNode;
  refs?: any;
  placeholder?: string;
  label?: string;
  value: string | undefined;
}

// interface IParceiros {
//   value: Options | any;
//   tipos: TipoPessoa[];
//   isRequired: boolean;
//   isDisabled?: boolean;
//   placeholder?: string;
//   setInvalid?: boolean;
//   iniInicializado: boolean;
//   invalidLabel?: string;
//   onChange(data: any, data1: boolean): any;
// }

export const BuscaVasilhame: React.FC<BuscaVasilhameProps> = forwardRef(
  (props) => {
    const {
      children,
      placeholder = 'Digite para buscar o Fornecedor',
      onChange,
      value = null,
    } = props;
    const inputRef = useRef<any>(null);

    useImperativeHandle(props.refs, () => ({
      handleClearVasilhame: () => handleClear(),
    }));

    const handleClear = () => {
      setFiltro(null);
    };

    const [filtro, setFiltro] = useState<Select | null>(null);
    const [store, setStore] = useState<Select[]>([]);

    useEffect(() => {
      if (value) {
        const hasExists = store.find(
          (reg) => Number(reg.value) === Number(value),
        );

        if (!hasExists) {
          return;
        }
        setFiltro({
          label: hasExists?.label,
          value: hasExists?.value,
        });
      }
    }, [value, store]);

    const handleChange = useCallback(
      (e: any) => {
        onChange(e);
        setFiltro(e);
      },
      [onChange],
    );

    // const loadOptions = async (inputValue: string) => {
    //   inputValue = inputValue.toString().toUpperCase();
    // };

    const [storeFiltered, setStoreFiltered] = useState<Select[]>([]);

    const getVasilhames = async () => {
      const res = await api.get('/produto/vasilhame');
      const { success, data, message } = res.data;
      const options = data.map((produto: Produto) => {
        return {
          label: `${produto.cod_produto} - ${produto.des_produto}`,
          value: produto.cod_produto,
        };
      });
      setStore(options);
      setStoreFiltered([]);
    };

    useEffect(() => {
      getVasilhames();
      // const getFornecedores = () => {};
      // setStore(options);
      // return options;
    }, []);

    const handleFilter = (e: string) => {
      if (e === '') {
        return setStoreFiltered([]);
      }
      const filtered = store.filter(
        (data) => data.label.indexOf(e.toUpperCase()) > -1,
      );
      setStoreFiltered(filtered);
    };

    return (
      <>
        <Field name="filtro" defaultValue="" label="Código Vasilhame">
          {() => (
            <>
              <Select
                options={storeFiltered}
                ref={inputRef}
                className="async-select-with-callback"
                classNamePrefix="react-select"
                placeholder={placeholder}
                onChange={handleChange}
                onInputChange={handleFilter}
                onMenuOpen={() => {
                  setFiltro(null);
                }}
                value={filtro}
                noOptionsMessage={() => 'Nenhum registro encontrado'}
              />
            </>
          )}
        </Field>
      </>
    );
  },
);
