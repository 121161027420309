import styled from 'styled-components';

export const AlertBox = styled.div`
  position: relative;
  width: auto;
  transition: 0.5s;
  .alert-class {
    > * {
      border-radius: 4px;
      position: relative;
      transition: 0.2s;
    }
    p {
      position: absolute;
      right: 0;
      border: none;
      color: #cf1919;
      transition: 0.2s;
    }
    .react-select__control {
      border-bottom: 2px solid #cf1919;
    }
    .react-select__control--is-focused {
      border-top: 2px solid;
      border-left: 2px solid;
      border-right: 2px solid;
      border-color: #cf1919;
      border-bottom: 2px solid #cf1919;
    }
    .sc-iqAclL {
      color: #cf1919 !important;
    }
    .lflqij {
      color: #cf1919 !important;
    }
  }
`;
