import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Field } from '@atlaskit/form';
import api from '~/services/api';
import { AlertBox } from './styles';
import Select, { AsyncSelect } from '@atlaskit/select';

interface Produto {
  cod_produto: string;
  des_produto: string;
  cod_gtin: string;
  des_departamento: string;
  qtd_est_atual: number;
}

interface Select {
  value: string | undefined;
  label: string | undefined;
}

interface BuscaProdutoProps {
  onChange(e: any): void;
  children?: ReactNode;
  refs?: any;
  placeholder?: string;
  label?: string;
  value: string | undefined;
  init: boolean;
  invalid?: boolean;
  valueEdit?: Select;
}

export const BuscaProduto: React.FC<BuscaProdutoProps> = forwardRef(
  (props, refs) => {
    const {
      value = null,
      init,
      invalid = false,
      placeholder = 'Digite para buscar o Fornecedor',
      onChange,
      label,
      valueEdit = { value: undefined, label: '' },
    } = props;
    const [isInvalid, setIsInvalid] = useState(false);
    const [inicializado, setInicializado] = useState(false);
    const [filtro, setFiltro] = useState<Select | null>(null);

    const inputRef = useRef<any>(null);

    useEffect(() => {
      setInicializado(init);
    }, [init]);

    useEffect(() => {
      setIsInvalid(invalid);
    }, [invalid]);

    const addAlert = useCallback((): string => {
      if (inicializado && isInvalid) {
        return 'alert-class';
      }
      return '';
    }, [inicializado, isInvalid]);

    useImperativeHandle(props.refs, () => ({
      handleClearProduto: () => handleClear(),
      focus: () => (inputRef.current ? inputRef.current.focus() : null),
    }));

    const handleClear = () => {
      setFiltro(null);
    };

    const [store, setStore] = useState<Select[]>([]);

    useEffect(() => {
      if (valueEdit.label === '') {
        if (value) {
          const hasExists = store.find(
            (reg) => Number(reg.value) === Number(value),
          );

          if (!hasExists) {
            loadOptions(value).then((data) => {
              setFiltro({
                label: data[0].label,
                value: data[0].label,
              });
            });
            return;
          }
          setFiltro({
            label: hasExists?.label,
            value: hasExists?.value,
          });
        }
      } else {
        setFiltro(valueEdit);
      }
    }, [value, store]);

    const handleChangeFiltro = useCallback(
      (e: any) => {
        onChange(e);
        setFiltro(e);
      },
      [onChange],
    );

    const loadOptions = async (inputValue: string) => {
      inputValue = inputValue.toUpperCase();
      const res = await api.get('/produto/consultaAssociado', {
        params: {
          loja: 1,
          parametro: inputValue,
        },
      });
      const { success, data, message } = res.data;
      const options = data.map((produto: Produto) => ({
        value: produto.cod_produto,
        label: `${produto.cod_produto} - ${produto.des_produto} - ${produto.cod_gtin}`,
        codProduto: produto.cod_produto,
        desDepartamento: produto.des_departamento,
        desProduto: produto.des_produto,
        qtd_estoque: produto.qtd_est_atual,
      }));
      setStore(options);
      return options;
    };

    return (
      <>
        <AlertBox>
          <div className={addAlert()}>
            <Field name="filtro" defaultValue="" label={label || 'Produto'}>
              {() => (
                <>
                  <AsyncSelect
                    ref={inputRef}
                    className="async-select-with-callback"
                    classNamePrefix="react-select"
                    loadOptions={loadOptions}
                    placeholder={placeholder}
                    onChange={handleChangeFiltro}
                    onMenuOpen={() => setFiltro(null)}
                    value={filtro}
                    noOptionsMessage={() => 'Nenhum registro encontrado'}
                    loadingMessage={() => 'Buscando...'}
                  />
                </>
              )}
            </Field>
          </div>
        </AlertBox>
      </>
    );
  },
);
